import { Text, Button } from "theme-ui";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { get, compact } from "sites-common/utils/lodash";
// import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import {
  abhyasStage /* , validateEmail */,
} from "sites-common/utils/validations";
import {
  // fetchProfileToken,
  fetchSrcmAPI,
  fetchSrcmProfileMe,
} from "sites-common/service-wrappers/profile-api";
// import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";
// import { DefaultButton } from "office-ui-fabric-react";
// import { patchSrcmProfileMe } from "sites-common/service-wrappers/profile-api/fetchSrcmProfileMe";
import {
  setUser,
  // setGlobals,
  setUserToken,
  showAlert,
  unsetUser,
} from "../../state/actions";
import {
  // selectorFirebaseAuthProfile,
  selectorGlobalConfig,
  selectorSrcmProfile,
  // selectorSrcmToken,
} from "../../state/selectors";
// import { useGlobals } from "../../globals";
// import Tos from "../Tos";
// import { Modal } from "../Modal";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { authStaticConfig } = getMandatoryEnv(["authStaticConfig"]);
const { keycloakConfig, myloginType } = getMandatoryEnv([
  "keycloakConfig",
  "myloginType",
]);

// import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

const isBrowser = () => typeof window !== "undefined";
const reloadPage = () => (isBrowser() ? window?.location?.reload() : {});
// const blockUnverifiedEmails = applyProdQaConfig({ prod: true, qa: false });

const re =
  /^(?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))$|^((?:[a-z]{2,3}(?:(?:-[a-z]{3}){1,3})?)|[a-z]{4}|[a-z]{5,8})(?:-([a-z]{4}))?(?:-([a-z]{2}|\d{3}))?((?:-(?:[\da-z]{5,8}|\d[\da-z]{3}))*)?((?:-[\da-wy-z](?:-[\da-z]{2,8})+)*)?(-x(?:-[\da-z]{1,8})+)?$|^(x(?:-[\da-z]{1,8})+)$/i;

export const fget = (ls, uid) => ({
  rec: ls,
  uid,
  id: get(ls, ["id"], ""),
  name: get(ls, ["name"], ""),
  email: get(ls, ["user_email"], false),
  phone: get(ls, ["mobile"], ""),
  abhyasiId: get(ls, ["ref"], false),
  country: get(ls, ["country", "name"], ""),
  country_id: get(ls, ["country", "id"], ""),
  state: get(ls, ["state", "name"], ""),
  state_id: get(ls, ["state", "id"], ""),
  city: get(ls, ["city"], ""),
  city_id: get(ls, ["city_id", "id"], ""),
  srcm_group: get(ls, ["srcm_group", "name"], ""),
  srcm_group_id: get(ls, ["srcm_group", "id"], ""),
  citizen: get(ls, ["citizen_of_country", "name"], ""),
  citizen_id: get(ls, ["citizen_of_country", "id"], ""),
  current_preceptor: get(ls, ["indiv_sittings_by", "name"], ""),
  current_preceptor_id: get(ls, ["indiv_sittings_by", "id"], ""),
  age: get(ls, ["age"], ""),
  gender: get(ls, ["gender"], ""),
  isPrefect: get(ls, ["record_type"], false) === "t",
  record_type: get(ls, ["record_type"], false),
  abhyasStage: abhyasStage(
    get(ls, ["record_type"], false),
    get(ls, ["ref"], false)
  ),
  isAtleastPractitioner: ["n", "a", "t"].includes(
    get(ls, ["record_type"], false)
  ),
  roles: get(ls, "user_roles", []),
  hasPositions: get(ls, "has_positions", false),

  address: compact([
    get(ls, ["street"], ""),
    get(ls, ["street2"], ""),
    get(ls, ["street3"], ""),
    get(ls, ["street4"], ""),
    get(ls, ["city"], ""),
    get(ls, ["postal_code"], ""),
  ])
    .filter((x) => !!x && x !== "false")
    .join(" ")
    .trim(),

  streetAddress: compact([
    get(ls, ["street"], ""),
    get(ls, ["street2"], ""),
    get(ls, ["street3"], ""),
    get(ls, ["street4"], ""),
  ])
    .filter((x) => !!x && x !== "false")
    .join(" ")
    .trim(),

  firstName: get(ls, "first_name", ""),
  lastName: get(ls, "last_name", ""),
  postalCode: get(ls, "postal_code", ""),
  photoUrl: get(ls, "photo_url", ""),
  userFirebaseUid: get(ls, "user_firebase_uid", ""),
});

const VerifyEmail = ({ email }) => {
  const firebase = useFirebase();
  const { enabledFireStore } = useSelector(selectorGlobalConfig);

  const doSignOut = useCallback(() => {
    firebase.logout();
    if (enabledFireStore) {
      firebase.app("auth").auth().signOut();
    }
  }, [firebase, enabledFireStore]);
  return (
    <div>
      <div style={{ float: "right" }}>
        <Button variant="secondaryFit" onClick={doSignOut}>
          Sign-out
        </Button>
      </div>
      <br />
      <br />
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Text variant="header">Heartfulness Profile</Text>
        <br />
        <Text variant="subtitle">{email}</Text>
      </div>

      <hr />
      <div style={{ textAlign: "center" }}>
        <Text variant="title">VERIFY EMAIL</Text>
        <br />
        <br />
      </div>

      <div style={{ textAlign: "center" }}>
        We have sent you a verification e-mail ({email}). Follow the link
        provided to finalize the signup process and refresh this page.
        <br />
        <br />
        Please contact us at{" "}
        <a href="mailto:profile@sahajmarg.org">profile@sahajmarg.org</a> if you
        do not receive it within a few minutes.
        <br />
        <br />
        <Button variant="variant3" onClick={reloadPage}>
          Refresh Page
        </Button>
      </div>
    </div>
  );
};

VerifyEmail.propTypes = {
  email: PropTypes.string.isRequired,
};

const FBDOLOGIN = (firebase, dispatch, enabledFireStore, sbt, setError) => {
  fetchSrcmAPI(
    "get-token-primary",
    sbt,
    "GET",
    {},
    {},
    true,
    {},
    "firestoreClient"
  )
    .then((res) => {
      let firebaseAuth = null;
      let firebaseStoreAuth = null;
      if (isBrowser()) {
        if (enabledFireStore) {
          firebaseAuth = firebase.app("auth").auth();
          firebaseStoreAuth = firebase.auth();
        } else {
          firebaseAuth = firebase.auth();
        }
      }

      // console.log("MySRCM Response", res);
      firebaseAuth
        .signInWithCustomToken(res)
        .then((/* r */ { user }) => {
          setError(null);
          // console.log("2nd Firebase Response", user);

          if (user && enabledFireStore) {
            user.getIdToken().then((authToken) => {
              // fetchSrcmAPI (authToken, _api = "me", _method = "GET", _params = {})
              fetchSrcmAPI(
                "get-token",
                authToken,
                "GET",
                {},
                {},
                true,
                {},
                "firestoreClient"
              )
                .then((res1) => {
                  // console.log("MySRCM Response", res);
                  firebaseStoreAuth
                    .signInWithCustomToken(res1)
                    .then((/* r */) => {
                      // console.log("2nd Firebase Response", r);
                    })
                    .catch((/* e */) => {
                      // console.error("Error firebaseApp: ", e);
                      dispatch(showAlert({ title: "Error FirebaseStoreApp" }));
                    });
                })
                .catch((/* e */) => {
                  // console.error("Error fetchT: ", e);
                  dispatch(showAlert({ title: "Error Firebase App 2" }));
                });
            });
          }
        })
        .catch((error1) => {
          // console.error("Error firebaseApp: ", error1);
          // Handle Errors here.
          const errorCode = error1.code;
          const errorMessage = error1.message;

          // doSignOut
          firebase.logout();
          if (enabledFireStore) {
            firebase.app("auth").auth().signOut();
          }

          dispatch(showAlert({ title: errorCode, message: errorMessage }));
          setError(`Error logging in: ${errorCode} ${errorMessage}`);
        });
    })
    .catch((e) => {
      // console.error("Error fetchT: ", e);
      let e2 = e.toString();
      if (
        e2 ===
        `Error: {"detail":"Authentication credentials were not provided."}`
      ) {
        e2 = "Login timed out. Please refresh the page to continue.";
      }
      dispatch(showAlert({ title: "Error", message: e2 }));
      setError(e2);
    });
};

export const AuthIsLoaded = ({ loader, children }) => {
  // const auth = useSelector(selectorFirebaseAuthProfile);
  const dispatch = useDispatch();
  // const { enabledFireStore } = useSelector(selectorGlobalConfig);
  // const firebase = useFirebase();
  // const srcmProfile = useSelector(selectorSrcmProfile);
  // const [inProgress, setInProgress] = useState(false);
  // const srcmtoken = useSelector(selectorSrcmToken);
  const [authLoaded, setAuthLoaded] = useState(true);

  useEffect(() => {
    const updateToken = async (newAuthData) => {
      if (typeof window !== "undefined") {
        const { getRefreshToken } = await import("hfn-oneauth/main");
        const params = keycloakConfig;
        await getRefreshToken(params)
          .then(async (res) => {
            if (res?.data?.access_token) {
              dispatch(
                setUserToken({
                  ...newAuthData?.srcmtoken,
                  token: res?.data?.access_token,
                })
              );
            } else {
              const payload = keycloakConfig;
              if (typeof window !== "undefined") {
                const { userLogout } = await import("hfn-oneauth/main");
                await userLogout(payload);
                dispatch(unsetUser());
                return true;
              }
            }
            return true;
          })
          .catch(() => {
            // console.log(e);
          })
          .finally(() => setAuthLoaded(false));
      }
      return true;
    };
    const authData =
      typeof window !== "undefined" ? localStorage.getItem("pstate") : "";
    const newAuthData = authData ? JSON.parse(authData) : "";
    if (!newAuthData?.srcmtoken?.token) {
      setAuthLoaded(false);
      return false;
    }
    updateToken(newAuthData);
    return true;
  }, [dispatch]);

  // useEffect(() => {
  //   fetch("https://fonts.googleapis.com/css?family=Roboto:400,500,700")
  //     .then(() => {
  //       dispatch(setGlobals("fb-blocked", false));
  //     })
  //     .catch(() => {
  //       dispatch(setGlobals("fb-blocked", false));
  //     });
  // }, [dispatch]);

  // const authLoaded = isLoaded(auth);
  // const storedEmail = srcmProfile?.email;

  // const [fbEmail, setFbEmail] = useState(null);
  // const [fbEmailVerified, setFbEmailVerified] = useState(false);

  // useEffect(() => {
  //   if (storedEmail && fbEmail && fbEmail !== storedEmail) {
  //     dispatch(unsetUser());
  //   }
  // }, [fbEmail, storedEmail, dispatch]);

  // useEffect(() => {
  //   if (authLoaded) {
  //     let authApp;
  //     if (enabledFireStore) {
  //       authApp = firebase.app("auth");
  //     } else {
  //       authApp = firebase.app();
  //     }
  //     const handleIdTokenChange = () =>
  //       authApp.auth().onIdTokenChanged((user) => {
  //         if (user) {
  //           setFbEmail(user.email);
  //           setFbEmailVerified(user.emailVerified);
  //           const sendVerification =
  //             blockUnverifiedEmails && !user.emailVerified && !!user.email;
  //           if (sendVerification) {
  //             user.sendEmailVerification().then(() => {
  //               // Email verification sent!
  //               // ...
  //               // dispatch(
  //               //   showAlert({
  //               //     message: `Email sent for verification to ${user.email}`,
  //               //   })
  //               // );
  //             });
  //           } else {
  //             user.getIdToken().then((authToken) => {
  //               const d = new Date();
  //               d.setMinutes(d.getMinutes() + 20);
  //               const ts = d.valueOf();
  //               dispatch(
  //                 setUserToken({
  //                   token: authToken,
  //                   fbName: user.displayName,
  //                   user: user.email,
  //                   uid: user.uid,
  //                   ts,
  //                   type: "fb",
  //                 })
  //               );
  //             });
  //           }
  //         } else {
  //           // Unset user only the browser type is window
  //           dispatch(unsetUser());
  //           setFbEmail(null);
  //         }
  //       });
  //     const unsubscribe = handleIdTokenChange();

  //     const refreshtoken = () => {
  //       const cuser = authApp.auth().currentUser;
  //       if (cuser) {
  //         cuser.getIdToken().then((authToken) => {
  //           const d = new Date();
  //           d.setMinutes(d.getMinutes() + 20);
  //           const ts = d.valueOf();
  //           dispatch(
  //             setUserToken({
  //               token: authToken,
  //               fbName: cuser.displayName,
  //               user: cuser.email,
  //               uid: cuser.uid,
  //               ts,
  //               type: "fb",
  //             })
  //           );
  //         });
  //       }
  //     };

  //     const timer1 = setInterval(refreshtoken, 20 * 60 * 1000);
  //     return () => {
  //       unsubscribe();
  //       clearInterval(timer1);
  //     };
  //   }
  //   return noop;
  // }, [
  //   authLoaded,
  //   dispatch,
  //   enabledFireStore,
  //   firebase,
  //   setFbEmailVerified,
  //   setFbEmail,
  // ]);

  // useEffect(() => {
  //   if (!inProgress) {
  //     if (srcmtoken.token && authLoaded) {
  //       const uid1 = srcmtoken.uid;
  //       const ts = new Date().valueOf();
  //       // const fbName1 = srcmtoken.fbName;

  //       // console.log("XXXX", srcmProfile, ts, srcmtoken, uid);
  //       if ((!srcmProfile || uid1 !== srcmProfile?.uid) && ts < srcmtoken.ts) {
  //         // stored token is still valid, fetch the profile using the token
  //         const authToken = srcmtoken.token;
  //         setInProgress(true);
  //         fetchSrcmProfileMe(authToken)
  //           .then((newsrcmprofile) => {
  //             dispatch(setUser(fget(newsrcmprofile, uid1)));
  //             // if (!newsrcmprofile?.name || !newsrcmprofile?.first_name) {
  //             // const parts = fbName1 ? fbName1.replaceAll("  ", " ").split(" ") : ["", ""];
  //             // const methodParams = {
  //             //   name: fbName1,
  //             //   first_name: parts[0],
  //             //   last_name:
  //             //     parts.length > 2
  //             //       ? parts.slice(1, parts.length).join(" ")
  //             //       : parts[1],
  //             // };
  //             // patchSrcmProfileMe(
  //             //   authToken,
  //             //   newsrcmprofile.id,
  //             //   methodParams
  //             // ).then((res) => {
  //             //   dispatch(setUser(fget(res, uid1)));
  //             //   setInProgress(false);
  //             // });
  //             // } else {
  //             setInProgress(false);
  //             // }
  //           })
  //           .catch((error) => {
  //             dispatch(showAlert(error));
  //             setInProgress(false);
  //             if (enabledFireStore) {
  //               firebase.app("auth").auth().signOut();
  //             } else {
  //               firebase.logout();
  //             }
  //           });
  //       }
  //     }
  //   }
  // }, [
  //   authLoaded,
  //   srcmtoken.token,
  //   // srcmtoken.fbName,
  //   srcmtoken.ts,
  //   srcmtoken.user,
  //   srcmtoken.uid,
  //   inProgress,
  //   srcmProfile,
  //   dispatch,
  //   setInProgress,
  //   enabledFireStore,
  //   firebase,
  // ]);

  // console.log( authLoaded,new Date( new Date().valueOf()), new Date( srcmtoken.ts), srcmtoken.user);

  // useEffect(() => {
  //   const ts1 = new Date().valueOf();
  //   const interval = srcmtoken.ts - ts1;
  //   if (!!srcmtoken.token && interval > 0) {
  //     // console.log(interval, new Date(srcmtoken.ts), srcmtoken.user);

  //     const timer = setTimeout(() => {
  //       // alert ('This will run after 3 second!');
  //       const sbt = srcmtoken.token;
  //       FBDOLOGIN(firebase, dispatch, enabledFireStore, sbt, noop);
  //     }, interval);
  //     return () => clearTimeout(timer);
  //   }
  //   return noop;
  // }, [srcmtoken.ts, srcmtoken.token, firebase, dispatch, enabledFireStore]);

  // if (!srcmtoken.token && !authLoaded) return loader;
  if (authLoaded) return loader;

  // if (fbEmail && blockUnverifiedEmails) {
  //   if (!fbEmailVerified) {
  //     return (
  //       <Modal>
  //         <VerifyEmail email={fbEmail} />
  //       </Modal>
  //     );
  //   }
  // }
  return children;
};

AuthIsLoaded.propTypes = {
  loader: PropTypes.element,
  children: PropTypes.element.isRequired,
};
AuthIsLoaded.defaultProps = {
  loader: "loading ...",
};

// const SrcmLoginForm = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [valid, setValid] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const dispatch = useDispatch();

//   useEffect(() => {
//     setValid(!!password && !!username && validateEmail(String(username)));
//   }, [password, username]);

//   const send = () => {
//     setLoading(true);
//     fetchProfileToken(username, password)
//       .catch((e) => {
//         setLoading(false);
//         setError(JSON.stringify(e));
//       })
//       .then((token) => {
//         setLoading(false);
//         if (token) {
//           const d = new Date();
//           d.setHours(d.getHours() + 8);
//           const ts = d.valueOf();
//           dispatch(
//             setUserToken({
//               token,
//               user: username,
//               ts,
//               uid: username,
//               type: "srcm",
//             })
//           );
//         } else {
//           setError("Invalid Response from Server or Network Error");
//         }
//       });
//   };

//   return (
//     <div>
//       <div style={{ width: "100%", margin: "20px 0" }}>
//         <div style={{ textAlign: "left", maxWidth: "300px", margin: "auto" }}>
//           <DynamicField
//             onChange={setUsername}
//             type="email"
//             value={username}
//             name="username"
//             label="Login Email Id"
//             icon="envelope"
//           />
//           <DynamicField
//             onChange={setPassword}
//             type="password"
//             value={password}
//             name="password"
//             label="Password"
//             icon="key"
//           />
//         </div>
//         <div style={{ height: 30 }}>
//           {loading && <Loader type="ThreeDots" color="#00BFFF" height={30} />}
//           {error && error}
//         </div>
//         <div>
//           <DefaultButton onClick={send} disabled={!valid}>
//             Sign In
//           </DefaultButton>
//         </div>
//       </div>
//       <Tos />
//     </div>
//   );
// };

export const useLoginWithSbt = (sbt) => {
  const dispatch = useDispatch();

  const firebase = useFirebase();
  const [error, setError] = useState("loading");

  const { enabledFireStore } = useSelector(selectorGlobalConfig);

  const getProfile = useCallback(async () => {
    const newsrcmprofile = await fetchSrcmProfileMe(sbt);
    if (newsrcmprofile) {
      dispatch(
        setUser(fget(newsrcmprofile, newsrcmprofile?.user_firebase_uid))
      );
      const d = new Date();
      d.setHours(d.getHours() + 8);
      const ts = d.valueOf();
      dispatch(
        setUserToken({
          token: sbt,
          fbName: newsrcmprofile.name,
          user: newsrcmprofile.email,
          uid: newsrcmprofile.user_firebase_uid,
          ts,
          type: "fb",
        })
      );
      setError(null);
    } else {
      setError("error");
    }
  }, [dispatch, sbt]);

  useEffect(() => {
    if (sbt) {
      if (myloginType === "keycloak") getProfile();
      else FBDOLOGIN(firebase, dispatch, enabledFireStore, sbt, setError);
    } else {
      setError(null);
    }
  }, [setError, dispatch, enabledFireStore, firebase, sbt, getProfile]);

  return { loading: error === "loading", error };
};

export const Login = ({ onSignedIn }) => {
  const firebase = useFirebase();
  // const auth = useSelector(selectorFirebaseAuthProfile);
  const srcmprofile = useSelector(selectorSrcmProfile);
  const [authStaticConfigData, setAuthStaticConfigData] = useState(null);

  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const [isFbBlocked] = useGlobals("fb-blocked", false);
  const { enabledFireStore, tosUrl, privacyPolicyUrl } =
    useSelector(selectorGlobalConfig);

  let firebaseAuth = null;
  if (isBrowser()) {
    if (enabledFireStore) {
      firebaseAuth = firebase.app("auth").auth();
    } else {
      firebaseAuth = firebase.auth();
    }
  }
  const firebaseStoreAuth =
    isBrowser() && enabledFireStore ? firebase.auth() : null;

  const foo = re.exec(Intl.NumberFormat().resolvedOptions().locale);

  const countryCode = foo[5];

  const getAuthConfigData = async () => {
    const response = await fetch(authStaticConfig);
    const data = await response.json();
    setAuthStaticConfigData(data || []);
  };

  useEffect(() => {
    // Firebase SMS cost optimization - disabling few countries.
    getAuthConfigData();
  }, []);

  return (
    !srcmprofile && (
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            textAlign: "center",
            paddingTop: "12px",
            fontSize: "1.3em",
            fontWeight: 700,
          }}
        >
          Heartfulness Profile
        </div>
        <div style={{ fontSize: "0.7em" }}>
          sign-in with an existing account or create a new account using any of
          the following sign-in methods
        </div>
        {authStaticConfigData && (
          <StyledFirebaseAuth
            uiConfig={{
              signInFlow: "popup",
              signInOptions: [
                {
                  provider: isBrowser()
                    ? firebase.auth.EmailAuthProvider.PROVIDER_ID
                    : null,
                  requireDisplayName: true,
                },
                {
                  provider: isBrowser()
                    ? firebase.auth.PhoneAuthProvider.PROVIDER_ID
                    : null,
                  defaultCountry: countryCode,
                  blacklistedCountries:
                    authStaticConfigData?.blacklistedCountries || [],
                  requireDisplayName: true,
                  recaptchaParameters: {
                    size: "invisible",
                  },
                },
                isBrowser()
                  ? {
                      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                      customParameters: { prompt: "select_account" },
                    }
                  : null,
                {
                  provider: isBrowser()
                    ? firebase.auth.FacebookAuthProvider.PROVIDER_ID
                    : null,
                  scopes: ["public_profile", "email"],
                },
              ],
              credentialHelper: "none",
              callbacks: {
                signInSuccessWithAuthResult: (authResult) => {
                  const { user } = authResult;
                  if (onSignedIn) {
                    onSignedIn(user);
                  }

                  if (user && enabledFireStore) {
                    user.getIdToken().then((authToken) => {
                      // fetchSrcmAPI (authToken, _api = "me", _method = "GET", _params = {})
                      fetchSrcmAPI(
                        "get-token",
                        authToken,
                        "GET",
                        {},
                        {},
                        true,
                        {},
                        "firestoreClient"
                      )
                        .then((res) => {
                          // console.log("MySRCM Response", res);
                          firebaseStoreAuth
                            .signInWithCustomToken(res)
                            .then((/* r */) => {
                              // console.log("2nd Firebase Response", r);
                            })
                            .catch((/* e */) => {
                              // console.error("Error firebaseApp: ", e);
                              dispatch(
                                showAlert({ title: "Error FirebaseStoreApp" })
                              );
                            });
                        })
                        .catch((/* e */) => {
                          // console.error("Error fetchT: ", e);
                          dispatch(
                            showAlert({ title: "Error FirebaseStoreApp" })
                          );
                        });
                    });
                  }

                  // firebase.handleRedirectResult(authResult).then(() => {
                  //   // history.push(redirectUrl); if you use react router to redirect
                  // });
                  return false;
                },
                signInFailure: (e) => dispatch(showAlert(e)),
                // uiShown: () => setLoading(false),
              },
              tosUrl: get(tosUrl, ["in"], "#"),
              privacyPolicyUrl: get(privacyPolicyUrl, ["in"], "#"),
            }}
            firebaseAuth={firebaseAuth}
          />
        )}
      </div>
    )
  );
};

Login.propTypes = {
  onSignedIn: PropTypes.func,
};
Login.defaultProps = {
  onSignedIn: null,
};

export default Login;
